import React, { Component } from "react";
import { API, Auth } from "aws-amplify";
import { Panel, FormGroup, FormControl, Glyphicon } from "react-bootstrap";
import { Helmet } from "react-helmet";
import LoaderButton from "../components/LoaderButton";
import Header from "../components/Header";
import "./Notes.css";
import "./NotFound.css";
import ls from "local-storage";

function ordinal_suffix_of(i) {
  var j = i % 10,
      k = i % 100;
  if (j === 1 && k !== 11) {
      return i + "st";
  }
  if (j === 2 && k !== 12) {
      return i + "nd";
  }
  if (j === 3 && k !== 13) {
      return i + "rd";
  }
  return i + "th";
}

export default class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: null,
      isDeleting: null,
      isCreator: false,
      isLoading: true,
      note: null,
      title: "", 
      description: "", 
      location: "",
      token: null,
      location_id: ls.get("location_id")
    };
  }

  async componentDidMount() {
    try {
      let user_info;
      try {
        user_info = await Auth.currentCredentials();
      } catch (e) {}

      const identity_id = user_info ? user_info.data.IdentityId : null;
      try {
        const note = await this.getNote(identity_id);
        this.setState({
          note,
          user_info,
          identity_id,
          isCreator: identity_id === note.created_by,
          title: note.title,
          description: note.description,
          location: note.location,
          isLoading: false
        });
      } catch (e) {
        this.setState({
          isLoading: false
        })

        setTimeout(function() {
          this.props.history.push("/");
        }.bind(this), 500)
      }

    } catch (e) {
      // TODO Improve this.
      alert(e);
    }
    
  }

  nth_most() {
    let suffix = ordinal_suffix_of(Math.round(this.state.note.avg_rank));
    return (
      suffix === "1st" ? 
    <b>most</b> : 
    (
    <React.Fragment>
      <b>{suffix}</b> most
    </React.Fragment>      
    )
  );
  }

  public_id() {
    return this.props.public_id || this.props.match.params.id;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log(prevState);
    // console.log(this.state.public_id);
    if (this.props.public_id && this.props.public_id !== prevProps.public_id) {
      this.setState({
        isLoading: true
      })
      const note = await this.getNote(this.state.identity_id);

      this.setState({
        note,
        isCreator: this.state.identity_id === note.created_by,
        title: note.title,
        description: note.description,
        location: note.location,
        isLoading: false
      });
    }
  }


  getNote(token) {
    const request = {
      queryStringParameters: {
        "cognitoIdentityId": token
      }
    };
    return API.get("notes", `/notes/${this.public_id()}`, request);
  }

  saveNote(note) {
    return API.put("notes", `/notes/${this.public_id()}`, {
      body: note
    });
  }

  deleteNote() {
    return API.del("notes", `/notes/${this.public_id()}`);
  }

  validateForm() {
    return this.state.location.length > 0 && this.state.title.length > 0 && this.state.description.length > 0; 
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {

    event.preventDefault();

    this.setState({ isSaving: true });

    try {

      await this.saveNote({
        title: this.state.title,
        description: this.state.description
      });
      this.props.history.push("/");
    } catch (e) {
      // TODO: Improve
      alert(e);
      this.setState({ isSaving: false });
    }
  }

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteNote();
      this.props.history.push("/");
    } catch (e) {
      // TODO: Improve
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

  supportIssue = supported => async event => {
    event.preventDefault();
    if (!this.props.isAuthenticated) {
      this.props.history.push(`/login?redirect=${this.props.location.pathname}${this.props.location.search}`);
      return;
    }
    await API.post("notes", "/support", {
      body: {public_id: this.state.note.public_id,
        supported: supported}
    });
    if (this.props.handler) {
      this.props.handler(this.state.note.public_id, supported);
    } else {
      this.props.history.push("/");
    }
  }

  details() {
    return (
      <React.Fragment>
        <hr />
      <Panel >
                  {this.state.note.num_supported ? (
                    <Panel.Body><b>{this.state.note.num_supported}</b> other people support this issue.</Panel.Body>
                  ) : (
                    <Panel.Body>No one has supported this issue yet.</Panel.Body>
                  )
                  }
                  </Panel>
                  
                  <Panel>
                  {this.state.note.avg_rank ? (
                    <Panel.Body>People typically rank this as their {this.nth_most()} important issue in <b>{this.state.note.location}</b>.</Panel.Body>
                  ) : (
                    <Panel.Body>No one has ranked this issue yet.</Panel.Body>
                  )
                }
      </Panel>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="Notes">
        {this.state.isLoading && <div style={{padding: "40px 0"}}><Glyphicon glyph="refresh" className="spinning2" /></div>}
        {!this.state.isLoading &&
        <div>
          {this.state.note ? (
              <div className="note">
                <form onSubmit={this.handleSubmit}>
                  {!this.props.public_id &&
                  <Header location={this.state.location}
                          history={this.props.history}/>
                  }

                  <Helmet>
                    <meta name="title" content={this.state.title}/>
                    <meta property="og:title" content={this.state.title}/>
                    <meta name="twitter:title" content={this.state.title}/>
                    <meta name="description" content={this.state.description}/>
                    <meta property="og:description"
                          content={this.state.description}/>
                    <meta name="twitter:description"
                          content={this.state.description}/>
                  </Helmet>
                  {this.state.isCreator ?
                  <div style={{paddingTop: "10px"}}>
                    <FormGroup controlId="title">
                      <FormControl
                          onChange={this.handleChange}
                          value={this.state.title}
                          type="text"
                      />
                    </FormGroup>
                    <FormGroup controlId="description">
                      <FormControl
                          onChange={this.handleChange}
                          value={this.state.description}
                          componentClass="textarea"
                      />
                    </FormGroup>

                    {this.details()}

                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isSaving}
                        text="Save"
                        loadingText="Saving…"
                    />
                    <LoaderButton
                        block
                        bsStyle="danger"
                        bsSize="large"
                        isLoading={this.state.isDeleting}
                        onClick={this.handleDelete}
                        text="Delete"
                        loadingText="Deleting…"
                    />
                  </div>
                  :
                      <div>
                        <Panel style={{marginTop: "20px"}}>
                        <Panel.Heading>
                          <b>{this.state.title}</b>
                        </Panel.Heading>
                          <Panel.Body>{this.state.description}</Panel.Body>
                        </Panel>

                        {this.details()}

                        {!this.state.isCreator && (this.state.location_id === null || this.state.location_id === this.state.note.place_id) &&
                        <div>
                        <LoaderButton
                            block
                            bsStyle="primary"
                            bsSize="large"
                            onClick={this.supportIssue("true")}
                            isLoading={this.state.isLoading}
                            disabled={this.state.note.support_dontcare === true}
                            text="Support!"
                        />
                        <LoaderButton
                            block
                            bsStyle="danger"
                            bsSize="large"
                            onClick={this.supportIssue("false")}
                            isLoading={this.state.isLoading}
                            disabled={this.state.note.support_dontcare === false}
                            text="Don't Care"
                        />
                      </div>
                    }
                    </div>
                  }
                </form>
              </div>) : (<div className="not-found"><h3>Issue Not Found</h3></div>)}
        </div>
        }
      </div>
      
    );
  }
}
