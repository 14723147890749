import React, { Component } from "react";
import { Auth } from "aws-amplify";
import FacebookButton from "../components/FacebookButton";
import { FormGroup, FormControl, ControlLabel, HelpBlock } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import ls from 'local-storage';
import "./Login.css";
import ConfirmationCode from "../components/ConfirmationCode";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      loginErrorMessage: "",
      FbErrorMessage: "",
      showConfirmation: false
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFbError = async (error) => {
    this.setState({ FbErrorMessage: error})
  };

  handleFbLogin = async (credentials) => {
    var AWS = require('aws-sdk');
    require('amazon-cognito-js');
    AWS.config.credentials = credentials;
    AWS.config.region = "us-east-1";
    var client = new AWS.CognitoSyncManager();
  
    client.refreshDatasetMetadata(function (err, datasetList) {
      if (datasetList.length === 0) {
        if (ls.get("location")) {
          client.openOrCreateDataset('locationData', function (err, dataset) {
            dataset.put('location', JSON.stringify(ls.get("location")), function (err, res) { });
            dataset.put('location_id', ls.get("location_id"), function (err, res) { });
            dataset.synchronize({
              onFailure: function(err) {
                 console.log(err);
              }
            });

            this.props.userHasAuthenticated(true);
          }.bind(this));
        } else {
          this.props.userHasAuthenticated(true);
        }
      } else {
          client.openOrCreateDataset('locationData', function (err, dataset) {
            dataset.synchronize({
              onSuccess: function(dataset_sync, newRecords) {
                dataset_sync.getAllRecords(function (err, res) {
                  ls.set(res[0].key, JSON.parse(res[0].value));
                  ls.set(res[1].key, res[1].value);
                  this.props.userHasAuthenticated(true);
                }.bind(this));
              }.bind(this)
            });
          }.bind(this));
      }
    }.bind(this));
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.signIn(this.state.email, this.state.password);
      const user = await Auth.currentAuthenticatedUser();

      if (user.attributes["custom:city_id"] && user.attributes.address) {
        try {
          // Users might have legacy `address` data (string), delete it to force a reset later if found.
          JSON.parse(user.attributes.address);
          ls.set("location", JSON.parse(user.attributes.address));
        } catch (e) {
          await Auth.updateUserAttributes(user, {
            "address": "",
            "custom:city_id": ""
          });
        }
        ls.set("location_id", user.attributes["custom:city_id"]);
        this.props.userHasAuthenticated(true);
      } else {
        if (ls.get("location")) {
          await Auth.updateUserAttributes(user, {
              "address": JSON.stringify(ls.get("location")),
              "custom:city_id": ls.get("location_id")
            });
          this.props.userHasAuthenticated(true);
        } else {
          this.props.userHasAuthenticated(true);
        }
      }
    } catch (e) {
      if (e.code === "UserNotConfirmedException") {
        this.setState({ showConfirmation: true});
      }
      this.setState({ loginErrorMessage: e.message });
      this.setState({ isLoading: false });
    }
  }

  renderLogin() {
    return (
    <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <Link className="forgot-password" to="/login/reset">Forgot password?</Link>
          <LoaderButton
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Login"
            loadingText="Logging in..."
          />
          {this.state.loginErrorMessage &&
          <HelpBlock>{this.state.loginErrorMessage}</HelpBlock>
          }
          <hr />
          <FacebookButton
            onLogin={this.handleFbLogin}
            onError={this.handleFbError}
          />
          
          {/* Comment out for now <GithubButton /> */}
          {this.state.FbErrorMessage &&
          <HelpBlock>Please try again.</HelpBlock>
          }
          <br />
          <h4 style={{textAlign: "center"}}>Not a member? <Link to="/signup"><b>Sign up</b></Link>.</h4>
        </form>
        );
      }

confirmSuccess = async event => {
  this.props.userHasAuthenticated(true);
  this.props.history.push("/");      
}

  renderConfirmation() {
    return (
      <ConfirmationCode email={this.state.email} password={this.state.password} confirmSuccess={this.confirmSuccess} />
    );
  }

  render() {
    return (
      <div className="Login">
      {this.state.showConfirmation
          ? this.renderConfirmation()
          : this.renderLogin()}
        
      </div>
    );
  }
}