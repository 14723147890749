const dev = {
  dev: true,
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://0fx5kh4xg2.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_fjV3VrFuz",
    APP_CLIENT_ID: "68qdemik4a6vdep42gr2q2fl8t",
    IDENTITY_POOL_ID: "us-east-1:20655d01-8b71-4eb8-8164-578ccc3b47b1"
  },
  social: {
    FB: "476831682764246"
  }
};

const prod = {
  dev: false,
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://p9rr3q8pag.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_S1cbNMtEA",
    APP_CLIENT_ID: "6atb2ph4r21glcolddmt877vjl",
    IDENTITY_POOL_ID: "us-east-1:1faa6eee-69f7-4f52-b2af-dbca2a29de7c"
  },
  social: {
    FB: "476831682764246"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  ...config
};