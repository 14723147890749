import React, { Component } from "react";
import "./About.css";

export default class About extends Component {

    render() {
        return (
          <div className="About">
            <div className="lander">
            <h2>The Voice of the People</h2>
            <hr />
                We feel like the political process has been in need of innovation for a long time.  Citizens should play a more active role in determining the most important issues in their communities.
                <p/>
                "<b>Take Issue</b>" enables everyone to create, support, and rank issues.  Over time communities can use this information to form a consensus on the priority of the issues they face.
                <p/>
                We view this platform as a <i>public service</i> where anyone can come to voice their concerns, without fear of discrimination or retribution.  We will not take corporate donations and rely entirely on donations to fund this project.
                <p/>
                With our voices combined, we will affect the change we want to see in our communities!
            </div>
          </div>
        );
      }

}
