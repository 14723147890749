import React, { Component } from "react";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import { ListGroup, ListGroupItem, Glyphicon, HelpBlock, Table, Navbar, ButtonGroup, Button, Badge, Breadcrumb } from "react-bootstrap";
import ls from 'local-storage';
import IssueDropdown from "../components/IssueDropdown";
import Location from "../components/Location";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Toggle from 'react-bootstrap-toggle';
import "./Home.css";
import Notes from "./Notes";
import "../css/bootstrap2-toggle.css";

const TOP = "top";
const SUPPORTED = "sup";
const NEW = "new";
     
const saveRanked = (result, location_id) => {
  let issue_rank_map = []
  for (let values of result) {
    let myArray = [values.public_id, values.rank];
    issue_rank_map.push(myArray)
    }

  API.post("notes", "/rank", {
    body: {location_id,
      issue_rank_map}
  });
}
      
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex, location_id) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  result.map((note, index) => 
  note.rank = index+1    
  );

  saveRanked(result, location_id);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination, location_id) => {
  const sourceClone = Array.from(source).filter(
    function (note) 
      { 
        return note.supported === true; 
      }
    );
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);
  
  destClone.map((destClone, index) => 
  destClone.rank = index+1    
  );

  saveRanked(destClone, location_id);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "#f8f8f8",
  paddingTop: "8px",
});

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.handler = this.handler.bind(this);
    this.noteHandler = this.noteHandler.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);

    // Clear local storage if `location` key non-JSON.
    try {
      JSON.stringify(ls.get("location"));
    } catch (e) {
      ls.remove("location");
    }

    this.state = {
      toggleActive: false,
      location: ls.get("location"),
      location_id: ls.get("location_id"),
      locationDenied: false,
      locationNotFound: false,
      isLoading: true,
      notes: [],
      rankedIssues: [],
      view: this.props.match.params.view || ls.get("view") || "new",
      topIssues: [],
      identity_id: null
    };
  }

  async noteHandler(note_public_id, supported) {
    const notes = this.state.notes.map(function (note) {
      if (note.public_id === note_public_id) {
        note.supported = supported === "true";
      }      
      return note;
    })
    
    const newIssues = this.state.notes.filter(note => note.supported === null);
    if (newIssues.length === 0) {
      this.handleViewChange(SUPPORTED);
    }
    // console.log(notes);
    this.setState({
      notes
    })
  }

  async handler(location, location_id, err) {
    this.setState({
      locatinDenied: false,
      locationNotFound: false
    })
    if (err) {
      this.setState({
        locationDenied: true
      });
      return;
    }
    if (location === "") {
      this.setState({
        locationNotFound: true
      });
      return;
    }
    this.setState({ 
      location: location,
      location_id: location_id,
      isLoading: true });

    ls.set("location", location);
    ls.set("location_id", location_id);

    try {
      // This will except if not logged in, or using federated identity.
      await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      if (!user.attributes["custom:city_id"] && !user.attributes.address) {
        await Auth.updateUserAttributes(user, {
          "address": JSON.stringify(location),
          "custom:city_id": location_id
        });
      }
    } catch (e) {
      try {
        const credentials = await Auth.currentCredentials();
      
        var AWS = require('aws-sdk');
        require('amazon-cognito-js');
        AWS.config.credentials = credentials;
        AWS.config.region = "us-east-1";
        var client = new AWS.CognitoSyncManager();
        client.listDatasets(function(err, datasetList) {
          if (datasetList.length === 0) {
            client.openOrCreateDataset('locationData', function(err, dataset) {
              dataset.put('location', JSON.stringify(location), function(err, res) {});
              dataset.put('location_id', location_id, function(err, res) {});
              dataset.synchronize({
                onFailure: function(err) {
                   console.log(err);
                }
            });
          });
        }
        });
      } catch (e) {
        console.log(e);
      }
    }

    // this only gets called if you are a new user, no need to pass token.
    const notes = await this.notes();
    const topIssues = await this.topIssues();
    this.setState({ 
      topIssues,
      notes, 
      isLoading: false});
  }

  async componentDidMount() {
    let geolocation_permission = await navigator.permissions.query({ name: 'geolocation' });
    if (geolocation_permission["state"] === "denied") {
      this.setState({locationDenied: "true"});
    }
    if (this.state.location) {
      try {
        // robust way to get either federated or non-federated user.
        let user = null;
        try {
          user = await Auth.currentCredentials();
        } catch (e) {}
        const token = user ? user.data.IdentityId : null;
        const data = await this.notes(token);
        const topIssues = await this.topIssues();
        let issues = [];
        let rankedIssues = [];
        // console.log(data);
        for (let issue of data) {
          if (issue.rank) {
            rankedIssues.push(issue);
          } else {
            issues.push(issue);
          }
        }
        rankedIssues.sort((a, b) => (a.rank > b.rank) ? 1 : -1)
        // console.log(issues); 
        this.setState({ 
          topIssues: topIssues,
          notes: issues, 
          rankedIssues: rankedIssues,
          identity_id: token });
      } catch (e) {
        // swallow these for now don't want user seeing them.
        // console.log(e);
      }
    }   

    // TODO: Refactor isLoading so it can work with Notes component.
    // if (this.state.view !== NEW) {
    this.setState({ isLoading: false });
    // }
  }

  notes(token) {
    const request = {
      queryStringParameters: {
        "cognitoIdentityId": token
      }
  };
    return API.get("notes", `/notes/location/${this.state.location_id}`, request);
  }

  topIssues() {
    return API.get("notes", `/issues/top/${this.state.location_id}`);
  }

  reloadNotes = async location_id => {
    if (this.state.location_id === location_id) {
      return;
    }

    await this.setState({ isLoading: true,
      location_id });
    ls.set("location_id", location_id);
    const data = await this.notes(this.state.identity_id);
      const topIssues = await this.topIssues();
      let issues = [];
      let rankedIssues = [];
      for (let issue of data) {
        if (issue.rank) {
          rankedIssues.push(issue);
        } else {
          issues.push(issue);
        }
      }
      rankedIssues.sort((a, b) => (a.rank > b.rank) ? 1 : -1)
      // console.log(issues); 
      this.setState({ 
        topIssues: topIssues,
        notes: issues, 
        rankedIssues: rankedIssues,
        isLoading: false });
  }

  handleNoteClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute("href"));
  }

  handleViewChange = async view => {
    this.props.history.push('/');
    ls.set("view", view);
    this.setState({ view });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  unrankIssue = issue => event => {
    event.preventDefault();
    let rankedIssues = this.state.rankedIssues;
    let notes = this.state.notes;
    rankedIssues = rankedIssues.filter(function(value) {
      return value !== issue;
    });
    rankedIssues.map((note, index) => 
      note.rank = index+1    
    );

    notes.push(issue);

    saveRanked(rankedIssues, issue.place_id);
    
    this.setState({
      notes,
      rankedIssues
    });
    
  }

  supportIssue = issue => supported => event => {
    event.preventDefault();
    API.post("notes", "/support", {
      body: {public_id: issue.public_id,
        supported: supported}
    });
  }

  onToggle = event => {
    this.setState({ toggleActive: !this.state.toggleActive });
  }

  /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
      droppable: 'notes',
      droppable2: 'rankedIssues'
  };

  getList = id => this.state[this.id2List[id]];

  onDragEnd = result => {
    const { source, destination } = result;

    console.log(source);
    console.log(destination);

    // dropped outside the list
    if (!destination) {
        return;
    }

    if (!this.props.isAuthenticated) {
      this.props.history.push(`/login?redirect=${this.props.location.pathname}${this.props.location.search}`);
      return;
    }

    if (source.droppableId === destination.droppableId) {
        const items = reorder(
            this.getList(source.droppableId),
            source.index,
            destination.index,
            this.state.location_id
        );

        let state = this.state.notes;

        if (source.droppableId === 'droppable2') {
            state = { rankedIssues: items };
        }

        this.setState(state);
    } else {
        const result = move(
            this.getList(source.droppableId),
            this.getList(destination.droppableId),
            source,
            destination,
            this.state.location_id
        );

        this.setState({
            notes: result.droppable,
            rankedIssues: result.droppable2
        });
    }
};

  renderNotesList(notes) {
    return Object.values(notes).length !== 0 ? notes.map((note, i) =>
        <Draggable key={note.public_id} draggableId={note.public_id} index={i}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
           
            <ListGroupItem
                          key={note.public_id}
                          className="issue"                          
                          style={{borderRadius: "0", marginBottom: "-1px"}}
                          // header={note.title.trim().split("\n")[0]}>
                          >
                          <Table style={{margin: "0", tableLayout: "fixed"}}>
              <tbody>
                <tr>
                  <td className="description" href={`/notes/${note.public_id}`}
                          onClick={this.handleNoteClick} >
                  <h4>{note.title}</h4>
                  
                          </td>
                          <td className="dropdown">
                            <IssueDropdown identity_id={this.state.identity_id} href={`/notes/${note.public_id}`} public_id={note.public_id} />
                          </td>
                </tr>
              </tbody>
            </Table>
            </ListGroupItem>
   
            </div>
          )}
            </Draggable>
        ) :
        <div style={{background: "rgb(248, 248, 248)", textAlign: "center"}}>
          No more supported issues, support more <a href="#default" onClick={() => this.handleViewChange("new")}><b>here</b></a>!
          </div>
  }

  renderLander() {
    return (
      <div className="lander">
        <h2>Welcome!</h2>
        <hr/>
        Take Issue was created to help discover the most important issues in all communities around the world!
        <p/>
        You can create your own issues, see issues others have created, support them, and then rank them in order of their importance to you.
        <p/>
        Together, we will use our collective voices to affect the change we want to see.
        <p/>
        Before we start, would you mind sharing your location <i>(it's just so we can find issues in your community)</i>?
        <hr/>
          {(this.state.locationDenied || this.state.locationNotFound) ? (
            <span>
            <HelpBlock>Oops! Something went wrong.  Please enter your location manually.</HelpBlock>
            <Location handler={this.handler} autocomplete="true" />
            </span>
          ) : (
            <Location handler={this.handler} />
          )}
      </div>
    );
  }

  renderNotes() {
    // const supportedIssues = this.state.notes.filter(
    //   function (note) 
    //     { 
    //       return note.supported === true; 
    //     }
    //   );
    const newIssues = this.state.notes.filter(
      function (note) 
        { 
          return note.supported === null; 
        }
      );
      
    return (
    <div className="notes">
    {this.state.isLoading && <div style={{padding: "40px 0"}}><Glyphicon glyph="refresh" className="spinning" /></div>}
    {!this.state.isLoading &&
    <div>
      <Navbar fluid>
        <Navbar.Header style={{display: "inline-block"}}>
          <Navbar.Brand>
            <Breadcrumb style={{textTransform: "uppercase", color: "black", fontWeight: "700", backgroundColor: "unset", marginBottom: 0, padding: "15px"}}>
            {
              ['country', 'administrative_area_level_1', 'locality'].map((element) => (
                this.state.location[element] && (
                  <Breadcrumb.Item
                    key={element}
                    onClick={() => this.reloadNotes(this.state.location[element].place_id)}
                    active={this.state.location_id === this.state.location[element].place_id}
                  >
                    {this.state.location[element].name}
                  </Breadcrumb.Item>
                )
              ))
            }
            </Breadcrumb>
          </Navbar.Brand>
        </Navbar.Header>
      <Navbar.Form pullRight style={{paddingRight: "0px"}}>
        <Button href="/notes/new" onClick={this.handleNoteClick}>Create Issue</Button>
      </Navbar.Form>

    </Navbar>
    <ButtonGroup justified>
      <Button href="#" onClick={() => this.handleViewChange("top")} active={this.state.view === TOP}>Top</Button>
      <Button href="#" onClick={() => this.handleViewChange("sup")} active={this.state.view === SUPPORTED}>Supported</Button>
      <Button href="#" onClick={() => this.handleViewChange("new")} active={this.state.view === NEW}>
      <Badge style={{background: "red", marginRight: "5px"}}>{newIssues.length}</Badge>
        New
      </Button>
    </ButtonGroup>
    </div>
            }
            {this.state.view === "sup" &&
            <div>
          {!this.state.isLoading &&
          <ListGroup>
          <DragDropContext onDragEnd={this.onDragEnd}>
          {(this.state.rankedIssues.length > 0 || this.state.notes.length > 0) &&
          <Droppable droppableId="droppable2">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                                                    <div className="bs-example">Ranked Issues</div>
                             
                            {this.state.rankedIssues.map((note, i) => (
                                <Draggable key={note.public_id} draggableId={note.public_id} index={i}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            <ListGroupItem
                                            className="issue"
                                            key={note.public_id}
                                            style={{borderRadius: "0", marginBottom: "-1px"}}
                                            // header={note.title.trim().split("\n")[0]}>
                                            >
                                            <Table style={{margin: "0", tableLayout: "fixed"}}>
                                              <tbody>
                                                <tr>
                                                  <td style={{width: "70px", padding: "0px", border: "0", verticalAlign: "middle", textAlign: "center", borderRight: "1px solid #ddd"}}
                                                   href={`/notes/${note.public_id}`}
                                                   onClick={this.handleNoteClick} >
                                                  <h1 style={{marginTop: "10px"}}>{note.rank ? note.rank : "-"}</h1>
                                                  </td>
                                                  <td className="description"
                                                   href={`/notes/${note.public_id}`}
                                                   onClick={this.handleNoteClick} >
                                                  <h4>{note.title}</h4>
                                                          </td>
                                                <td className="dropdown">
                                                  <IssueDropdown identity_id={this.state.identity_id} href={`/notes/${note.public_id}`} public_id={note.public_id} ranked={true} unrankIssue={this.unrankIssue(note)} supportIssue={this.supportIssue(note)} />
                                                </td>
                                                </tr>
                                              </tbody>
                                            </Table>
                                            </ListGroupItem>
                                              </div >
                                          )}
                                          </Draggable>
                      ))}
                      {this.state.rankedIssues.length === 0 ?
                       (<ListGroupItem style={{border: "dashed rgb(209, 209, 209)", padding: "5px"}}>
                              <h4 style={{textAlign: "center"}}>
                              <i style={{color: "#777", fontWeight: "800"}}>Drag Supported Issues here</i>
                              </h4>
                        </ListGroupItem>)
                      : provided.placeholder
                      }
                        </div>
                    )}
                </Droppable>
          }
          <Droppable droppableId="droppable" isDropDisabled={true}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                <div className="bs-example">Supported Issues</div>
                {this.renderNotesList(this.state.notes.filter(
                  function (note) 
                    { 
                      return note.supported === true; 
                    }
                  )
                )}
              
            </div>
            )}
          </Droppable>
            </DragDropContext>

          </ListGroup>
          }
          </div>
          }
          {!this.state.isLoading && this.state.view === "top" &&
          <div style={{background: "rgb(248, 248, 248)", paddingTop: "8px"}}>
            <ListGroup>
              <div className="bs-example">Top Issues</div>
              {Object.values(this.state.topIssues).length !== 0 ? this.state.topIssues.map((issue, i) => (
                <ListGroupItem
                className="issue"
                key={issue.public_id}
                style={{borderRadius: "0", marginBottom: "-1px"}}
                >
                <Table style={{margin: "0", tableLayout: "fixed"}}>
                  <tbody>
                    <tr>
                      <td style={{width: "70px", padding: "0px", border: "0", verticalAlign: "middle", textAlign: "center", borderRight: "1px solid #ddd"}}
                        href={`/notes/${issue.public_id}`}
                        onClick={this.handleNoteClick}>
                      <h1 style={{marginTop: "10px"}}>{i+1}</h1>
                      </td>
                      <td className="description"
                        href={`/notes/${issue.public_id}`}
                        onClick={this.handleNoteClick}>
                      <h4>{issue.title}</h4>
                              </td>
                              <td className="dropdown">
                                <IssueDropdown identity_id={this.state.identity_id}  public_id={issue.public_id} href={`/notes/${issue.public_id}`} />
                              </td>
                    </tr>
                  </tbody>
                </Table>
              </ListGroupItem>
              )
            ) :
            <div style={{background: "rgb(248, 248, 248)", paddingTop: "8px", textAlign: "center"}}>
              There are no ranked or supported issues in your community.
              </div>}
              </ListGroup>
            </div>
            }
            {!this.state.isLoading && this.state.view === NEW &&
          <div style={{background: "rgb(248, 248, 248)", paddingTop: "8px"}}>
          <ListGroup>
            <div className="bs-example">New Issues
            {newIssues.length > 1 &&
            <span
            style={{float: "right", letterSpacing: "0px", textTransform: "none"}}>
              List Mode
            <Toggle
              onClick={this.onToggle}
              size="sm"
              active={this.state.toggleActive}
              style={{marginLeft: "5px"}}
            />
            </span>
            }
            </div>
            
            {this.state.notes.filter(note => note.supported === null).length > 0 ? (
              !this.state.toggleActive ? (
              <Notes {...this.props} disableSpinner={true} public_id={this.state.notes.filter(note => note.supported === null)[0].public_id} handler={this.noteHandler}  />
              ) : 
                newIssues.map((issue, i) => (
                  <ListGroupItem
                  key={issue.public_id}
                  className="issue"                          
                  style={{borderRadius: "0", marginBottom: "-1px"}}
                  // header={note.title.trim().split("\n")[0]}>
                  >
                  <Table style={{margin: "0", tableLayout: "fixed"}}>
                    <tbody>
                      <tr>
                        <td className="description" href={`/notes/${issue.public_id}`}
                                onClick={this.handleNoteClick} >
                        <h4>{issue.title}</h4>
                                </td>
                                <td className="dropdown">
                                  <IssueDropdown identity_id={this.state.identity_id} public_id={issue.public_id} href={`/notes/${issue.public_id}`} supported={issue.supported} supportIssue={this.supportIssue(issue)} />
                                </td>
                      </tr>
                    </tbody>
                  </Table>
                  </ListGroupItem>
                )
              )
            ) :
          <div style={{background: "rgb(248, 248, 248)", paddingTop: "8px", textAlign: "center"}}>
            There are no new issues in your community.
            </div>}
            </ListGroup>
          </div>
            }             
            </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.state.location ? this.renderNotes() : this.renderLander()}
      </div>
    );
  }
}
