import React, { Component } from "react";
import { ButtonToolbar, Dropdown, MenuItem, Glyphicon } from "react-bootstrap";
import { API } from "aws-amplify";

export default class IssuesDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  openShare = async () => {
    let url = window.location.origin + this.props.href;
    if (navigator && navigator.share) {
      navigator.share({
        url: url
      })
    } else {
      url = "https://www.facebook.com/sharer/sharer.php?u=" + url;
      var win = window.open(url, '_blank');
      win.focus();
    }
  };

  resolveIssue = async () => {
    API.put("notes", `/resolve/${this.props.public_id}`);
  }

  render() {
    return (
        <ButtonToolbar style={{float: "right"}} >
        <Dropdown pullRight>
          <Dropdown.Toggle noCaret>
            <Glyphicon glyph="option-vertical" />
          </Dropdown.Toggle>
          <Dropdown.Menu >
            {this.props.ranked &&
            <MenuItem eventKey="1" onClick={this.props.unrankIssue}>Unrank</MenuItem>
            }
            {/*
            #TODO: Add supporting on dropdown maybe...
             {!this.props.supported &&
            <MenuItem eventKey="2" onClick={this.props.supportIssue("true")}>Support</MenuItem>
            }
            {this.props.supported &&
            <MenuItem eventKey="3" onClick={this.props.supportIssue("false")}>Don't Care</MenuItem>
            } */}
            <MenuItem eventKey="2" onClick={this.openShare}>Share</MenuItem>
            {this.props.identity_id === "us-east-1:b69dae73-150b-4a4a-ae26-5a41504e066b" &&
            <MenuItem eventKey="3" onClick={this.resolveIssue}>Resolve</MenuItem>
            }
          </Dropdown.Menu>
        </Dropdown>
        </ButtonToolbar>
    );
  }
}