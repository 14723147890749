import React, { Component } from "react";
import { Navbar, ButtonGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Header.css";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
    //   isLoading: false
    };
  }

  async componentDidMount() {}

  handleNoteClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute("href"));
  }

  render() {
    return (
        <div>
        <Navbar fluid style={{borderTop: "1px solid #e7e7e7", borderBottom: "1px solid #e7e7e7"}}>
        <Navbar.Header style={{display: "inline-block"}}>
        <Navbar.Brand>
        <Link to="/" style={{textTransform: "uppercase", color: "black", fontWeight: "700"}}>{this.props.location}</Link>
        </Navbar.Brand>
        </Navbar.Header>
        <Navbar.Form pullRight style={{paddingRight: "0px"}} >
        <Button href="/notes/new" onClick={this.handleNoteClick} disabled={this.props.disabled}>Create Issue</Button>
        </Navbar.Form>

        </Navbar>
        <ButtonGroup justified>
        {/* <Button href="#" onClick={() => this.handleViewChange("top")} active={this.state.view === "top"}>Top Issues</Button>
        <Button href="#" onClick={() => this.handleViewChange("")} active={this.state.view !== "top"}>All Issues</Button> */}
        <Button href="/top" >Top</Button>
        <Button href="/sup" >Supported</Button>
        <Button href="/new" >New</Button>        
        </ButtonGroup>
        </div>
    );
  }
}