import React, { Component, Fragment } from "react";
import { Auth } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {Helmet} from "react-helmet";
import Routes from "./Routes";
import config from "./config";
import "./App.css";
import ReactGA from 'react-ga';
if (!config.dev) {
  ReactGA.initialize('UA-137580707-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  authUser = async (retry) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        this.userHasAuthenticated(true);
        this.setState({ isAuthenticating: false });
      }
    }
    catch(e) {
      // console.log(e);
      if (!retry) {
        setTimeout(() => this.authUser(true), 500);
      }
    }

    if (retry) {
    this.setState({ isAuthenticating: false });
  }
  }

  async componentDidMount() {
    this.loadFacebookSDK();
    this.authUser(false);
  }

  

  loadFacebookSDK() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : config.social.FB,
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v3.2'
      });
    };
  
    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();

    this.userHasAuthenticated(false);

    this.props.history.push("/login");
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };
  
    return (
      !this.state.isAuthenticating &&
      <div className="App container">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Take Issue</title>
                <meta name="title" content="Take Issue" />
                <meta property="og:title" content="Take Issue" />
                <meta name="twitter:title" content="Take Issue" />
                <meta name="description" content="Got a Problem? Take Issue" />
                <meta property="og:description" content="Got a Problem? Take Issue" />
                <meta name="twitter:description" content="Got a Problem? Take Issue" />
                <meta name="twitter:image" content="https://takeissue.app/android-chrome-256x256.png" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
        <Navbar fluid collapseOnSelect className="top">
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/"><img src={process.env.PUBLIC_URL + '/favicon-32x32.png'} style={{marginTop: "-5px"}} alt="Take Issue" title="Take Issue"/></Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <LinkContainer to="/about">
                <NavItem>About</NavItem>
              </LinkContainer>
              <NavItem href="https://medium.com/take-issue" target="_blank">Blog</NavItem>
              <NavItem href="https://www.patreon.com/take_issue" target="_blank">Donate</NavItem>
              <NavItem href="https://twitter.com/TakeIssue" target="_blank">Twitter</NavItem>
              <LinkContainer to="/privacy">
                <NavItem>Privacy</NavItem>
              </LinkContainer>
              {/* <LinkContainer to="/feedback">
                <NavItem>Feedback</NavItem>
              </LinkContainer> */}
            </Nav>
            <Nav pullRight>
              {this.state.isAuthenticated
                ? <Fragment>
                    {/* <LinkContainer to="/settings">
                      <NavItem>Settings</NavItem>
                    </LinkContainer> */}
                    <NavItem onClick={this.handleLogout}>Logout</NavItem>
                  </Fragment>
                : <Fragment>
                    <LinkContainer to="/signup">
                      <NavItem>Signup</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <NavItem>Login</NavItem>
                    </LinkContainer>
                  </Fragment>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={childProps} />
      </div>
    );
  }
}

export default withRouter(App);
