import React, { Component } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl, HelpBlock } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import ls from 'local-storage';
import Header from "../components/Header";
import "./NewNote.css";

export default class NewNote extends Component {
  constructor(props) {
    super(props);

    const location = Object.values(ls.get("location")).find(obj => obj.place_id === ls.get("location_id"));

    this.state = {
      isLoading: null,
      title: "", 
      description: "", 
      location: location.name,
      place_id: ls.get("location_id"),
      rateLimit: 0,
    };

  }

  createNote(note) {
    return API.post("notes", "/notes", {
      body: note
    });
  }

  validateForm() {
    return this.state.location.length > 0 && 
    this.state.title.length > 0 && 
    this.state.description.length > 0; 
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.createNote({
        title: this.state.title,
        description: this.state.description,
        location: this.state.location,
        place_id: this.state.place_id
      });
      this.props.history.push("/");
    } catch (e) {
      // TODO: Do something better here.
      console.log(e.response);
      this.setState({ isLoading: false,
                      rateLimit: Math.ceil(e.response.data.rateLimit) 
                    });
    }
  }

  render() {
    return (
      <div className="NewNote">
      <Header location={this.state.location} disabled={true}/>
        <form onSubmit={this.handleSubmit}>
        <div style={{paddingTop: "10px"}} >
          <FormGroup controlId="title">
              <FormControl
                onChange={this.handleChange}
                value={this.state.title}
                placeholder="Title"
                type="text"
              />
            </FormGroup>
            <FormGroup controlId="description">
              <FormControl
                onChange={this.handleChange}
                value={this.state.description}
                placeholder="Description"
                componentClass="textarea"
              />
            </FormGroup>
            <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
          {this.state.rateLimit > 0 &&
          <HelpBlock>You are doing that too often, please wait {this.state.rateLimit} minutes to create a new issue.</HelpBlock>
          }
          </div>
        </form>
      </div>
    );
  }
}
