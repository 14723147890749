import React from 'react';
import LocationAutocomplete from 'location-autocomplete';
import loadGoogleMapsApi from 'load-google-maps-api-2';
import LoaderButton from "../components/LoaderButton";

export default class Location extends React.Component {

    constructor(props) {
        super(props);
       
        this.state = {
          isLoading: false,
        };
      }
    
    getCurrentPosition = (options = {}) => {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    };
    
    handleGeoClick = async event => {
        event.preventDefault();
        this.setState({isLoading: true });
       
        let position;
        try {
            position = await this.getCurrentPosition();
        } catch (err) {
            this.props.handler(null, null, err);
            this.setState({isLoading: false });
            return;
        }
        const { latitude, longitude } = position.coords;

        loadGoogleMapsApi({
            key: "AIzaSyArXZu83pj7TFmTEizzs7-DKJTMnSA6pEQ",
          }).then(function (googleMaps) {
            var service = new googleMaps.Geocoder();
            var latlng = {lat: latitude, lng: longitude};
            service.geocode({'location': latlng }, function(results, status) {
                let location = {};
                let place_id = "";
                results.forEach(function(value) {
                    for (let address_type of ['locality', 'administrative_area_level_1', 'country']) {
                        if (value.types.includes(address_type)) {
                            let components = value.address_components;
                            let array_length = 0;
                            for (let component of components) {
                                if (component.types.includes(address_type)) {
                                    if (array_length === 0 || value.address_components.length < array_length) {
                                        let address_details = {"name": component.long_name, "place_id": value.place_id};
                                        location[address_type] = address_details;
                                        array_length = value.address_components.length;
                                    }
                                }
                            }
                        }
                    }
                });
                place_id = location['country'].place_id;

                this.setState({
                    isLoading: false
                })
                this.props.handler(location, place_id);
          }.bind(this));
        }.bind(this));
    }

    render() {
        return (
            <span>
            {navigator.geolocation && this.props.autocomplete !== "true" &&
             <LoaderButton
             bsClass="success"
             className="btn btn-success btn-lg"
             onClick={this.handleGeoClick}
             isLoading={this.state.isLoading}
             text="Find Issues Near Me"
             loadingText="Finding issues..."
           />
           }
                
            {this.props.autocomplete === "true" &&
            <LocationAutocomplete
                onChange={() => {}}
                className="form-control"
                name="location"
                placeholder="Enter your location..."
                locationType="(regions)"
                googleAPIKey="AIzaSyArXZu83pj7TFmTEizzs7-DKJTMnSA6pEQ"
                type="input"
                onDropdownSelect={
                async (resp) => {
                    let location = {};
                    let place_id = "";
                    const value = resp.autocomplete.getPlace();

                    for (let address_type of ['locality', 'administrative_area_level_1', 'country']) {
                        let components = value.types;
                        let array_length = 0;
                        for (let component of components) {
                            if (component.includes(address_type)) {
                                if (array_length === 0 || value.address_components.length < array_length) {
                                    let address_details = {"name": value.name, "place_id": value.place_id};
                                    location[address_type] = address_details;
                                    place_id = value.place_id;
                                    array_length = value.address_components.length;
                                }
                            }
                        }
                    }
                    this.props.handler(location, place_id);
                }}
            />
            }
            </span>
        );
    }
}